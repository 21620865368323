<template>
  <CRow>
    <table class="table table-dark">
      <thead>
        <tr>
          <th scope="col">Property Id</th>
          <th scope="col">Offered Price</th>
          <th scope="col">Start Date</th>
          <th scope="col">Contract Length</th>
          <th scope="col">User</th>
          <!-- <th scope="col">Status</th> -->
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item['id']">
          <td>
            {{
              item["contract_details"]
                ? item["contract_details"]["property_id"]
                : null
            }}
          </td>
          <td>{{ item["offered_price"] | toCurrency }}</td>
          <td>
            {{
              item["contract_details"]
                ? item["contract_details"]["start_date"]
                : null
            }}
          </td>
          <td>
            {{
              item["contract_details"]
                ? item["contract_details"]["contract_length"]
                : null
            }}
            Months
          </td>
          <td>
            <ul>
              <li>
                Name :
                {{
                  item["user_details"] ? item["user_details"]["name"] : "N/A"
                }}
              </li>
              <li>
                Email :
                {{
                  item["user_details"] ? item["user_details"]["email"] : "N/A"
                }}
              </li>
              <li>
                Mobile :
                {{ item["phone_no"] ? item["phone_no"] : "N/A" }}
              </li>
            </ul>
          </td>
          <!-- <td class="upper text-capitalize">{{ item["status"] }}</td> -->
          <td>
            <select
              @change="onClickEdit(item['id'], $event.target.value)"
              class="px-3"
              v-if="item['status'].toLowerCase() === 'pending'"
            >
              <option
                value="approved"
                :selected="item['status'].toLowerCase() === 'approved'"
              >
                Approved
              </option>
              <option
                value="pending"
                :selected="item['status'].toLowerCase() === 'pending'"
              >
                Pending
              </option>
              <option
                value="reject"
                :selected="item['status'].toLowerCase() === 'reject'"
              >
                Reject
              </option>
            </select>
            <div v-else>
              <p class="upper text-capitalize">
                {{ item["status"] }}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-end w-100 mb-3">
      <nav aria-label="Page navigation example" class="mr-3">
        <ul class="pagination mb-0">
          <li class="page-item">
            <a
              class="page-link"
              @click="onLoad('prev')"
              aria-label="Previous"
              id="prev"
            >
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li
            class="page-item"
            :class="{ active: t === current_page }"
            v-for="t in totalPage"
            :key="t"
          >
            <a class="page-link" @click="onPageLoad(t)">{{ t }}</a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              @click="onLoad('next')"
              aria-label="Next"
              id="next"
            >
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
      <select v-model="selected" @change="onSelect" class="px-3">
        <option :selected="true">5</option>
        <option>10</option>
        <option>50</option>
      </select>
    </div>
  </CRow>
</template>

<script>
// import { getProperties, deleteProperty } from "@/api/properties";
import { getAllOffers, updateOfferStatus } from "@/api/contracts";
import { getToken } from "@/utils/auth";

export default {
  name: "Offers",
  data() {
    return {
      items: [],
      fields: [{ key: "location" }, { key: "price" }, { key: "actions" }],
      modalShow: false,
      property_id: 0,
      perPage: 5,
      prev: "",
      next: "",
      last: "",
      totalData: "",
      totalPage: "",
      current_page: 1,
      value: "",
      selected: 5,
      filters: "",
    };
  },
  created() {
    getAllOffers(1, this.perPage, this.filters)
      .then((res) => {
        this.items = (res.data && res.data.data.data) || [];
        this.perPage = res.data.data.per_page;
        this.prev = res.data.data.prev_page_url;
        this.next = res.data.data.next_page_url;
        if (this.prev === null) {
          document.getElementById("prev").classList.add("disable_a_href");
        }
        this.last = res.data.data.last_page_url;
        if (this.next === null) {
          document.getElementById("next").classList.add("disable_a_href");
        }
        this.totalData = res.data.data.total;
        this.totalPage = Math.ceil(this.totalData / this.perPage);
      })
      .catch((error) => {
        // reject(error)
        console.log(error, "error");
      });
  },
  paginationProps: {
    align: "center",
    doubleArrows: false,
    previousButtonHtml: "prev",
    nextButtonHtml: "next",
  },
  methods: {
    onInput() {
      getAllOffers(1, this.perPage, this.filters)
        .then((res) => {
          this.items = (res.data && res.data.data.data) || [];
          this.perPage = res.data.data.per_page;
          this.prev = res.data.data.prev_page_url;
          if (this.prev === null) {
            document.getElementById("prev").classList.add("disable_a_href");
          }
          this.last = res.data.data.last_page_url;
          if (this.next === null) {
            document.getElementById("next").classList.add("disable_a_href");
          }
          this.next = res.data.data.next_page_url;
          this.totalData = res.data.data.total;
          this.totalPage = Math.ceil(this.totalData / this.perPage);
        })
        .catch((error) => {
          // reject(error)
          console.log(error, "error");
        });
      console.log("ko");
    },
    onSelect(event) {
      let value = event.target.value;
      getAllOffers(1, value, this.filters)
        .then((res) => {
          this.items = (res.data && res.data.data.data) || [];
          this.isLoading = false;
          this.perPage = res.data.data.per_page;
          this.prev = res.data.data.prev_page_url;
          if (this.prev === null) {
            document.getElementById("prev").classList.add("disable_a_href");
          }
          this.last = res.data.data.last_page_url;
          if (this.next === null) {
            document.getElementById("next").classList.add("disable_a_href");
            this.next = res.data.data.next_page_url;
          }
          this.totalData = res.data.data.total;
          this.totalPage = Math.ceil(this.totalData / this.perPage);
        })
        .catch((error) => {
          // reject(error)
          console.log(error, "error");
        });
    },
    onLoad(value) {
      if (value === "prev") {
        if (this.current_page != 1)
        this.current_page = this.current_page - 1;
      } else if (this.current_page < this.totalPage) {
        this.current_page = this.current_page + 1;
      }
      this.onPageLoad(this.current_page);
    },
    onPageLoad(page) {
      getAllOffers(page, this.perPage, this.filters)
        .then((res) => {
          this.items = (res.data && res.data.data.data) || [];
          this.perPage = res.data.data.per_page;
          this.prev = res.data.data.prev_page_url;
          this.last = res.data.data.next_page_url;
          if (this.prev !== null) {
            document.getElementById("prev").classList.remove("disable_a_href");
          } else {
            document.getElementById("prev").classList.add("disable_a_href");
          }
          if (this.next !== null) {
            document.getElementById("next").classList.remove("disable_a_href");
          } else {
            document.getElementById("next").classList.add("disable_a_href");
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
      this.current_page = page;
    },

    onClickEdit(id, status) {
      const requestData = {
        offer_id: id,
        status: status,
      };
      console.log("requestData", requestData);
      updateOfferStatus(requestData)
        .then((res) => {
          console.log("res", res.data.data);
          this.onPageLoad(this.current_page);
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    getCreatePage() {
      this.$router.push("/admin/properties/add");
    },
    onHideModal() {
      this.modalShow = !this.modalShow;
      this.property_id = 0;
    },
    savePropertyData(data, id = null) {
      if (id) {
        var foundIndex = this.items.findIndex((x) => x.id == id);
        this.items[foundIndex] = data;
      } else {
        this.perPage = data.per_page;
        this.totalData = data.total;
        this.totalPage = Math.ceil(this.totalData / this.perPage);
        this.items = data.data;
      }
    },
    deleteProperty(id) {
      if (
        confirm(
          "Do you really want to delete property? if you delete this all associated contracts will be deleted."
        )
      ) {
        deleteProperty(id, getToken())
          .then((res) => {
            this.items = (res.data && res.data.data.data) || [];
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message,
            });
            this.perPage = res.data.data.per_page;
            this.totalData = res.data.data.total;
            this.totalPage = Math.ceil(this.totalData / this.perPage);
          })
          .catch((error) => {
            if (error.response.status == 501) {
              this.$notify({
                group: "notify",
                type: "error",
                text: error.response.data.data,
              });
            }
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.forDiv {
  display: flex;
  justify-content: space-between;
}
.disable_a_href {
  pointer-events: none;
}
.active {
  background-color: blue;
}
li {
  cursor: pointer;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ml-1 {
  margin-left: 1em;
}
</style>
